import React from 'react';
import { useTranslation } from 'react-i18next';
import '../AboutUs.css'
import './Rgpd.css'


const Rgpd = () => {

    const [t, i18n] = useTranslation("global")

    return (
        <main className='About_Us'>
            <section className='AboutUs_header'>
                <h1 className='animationDown'>{t("rgpd.titre")}</h1>
            </section>
            <section className='Rgpd'>
                <p>{t("rgpd.date")}</p>
                <div className='Rgpd_texte'>
                    <h2>{t("rgpd.textes.titre1")}</h2>
                    <p>{t("rgpd.textes.texte1")}</p>
                </div>
                <div className='Rgpd_texte'>
                    <h2>{t("rgpd.textes.titre2")}</h2>
                    <p>{t("rgpd.textes.texte2")}</p>
                </div>
                <div className='Rgpd_texte'>
                    <h2>{t("rgpd.textes.titre3")}</h2>
                    <p>{t("rgpd.textes.texte3")}</p>
                </div>
                <div className='Rgpd_texte'>
                    <h2>{t("rgpd.textes.titre4")}</h2>
                    <p>{t("rgpd.textes.texte4")}</p>
                </div>
                <div className='Rgpd_texte'>
                    <h2>{t("rgpd.textes.titre5")}</h2>
                    <p>{t("rgpd.textes.texte5")}</p>
                </div>
                <div className='Rgpd_texte'>
                    <h2>{t("rgpd.textes.titre6")}</h2>
                    <p>{t("rgpd.textes.texte6")}</p>
                </div>
                <div className='Rgpd_texte'>
                    <h2>{t("rgpd.textes.titre7")}</h2>
                    <p>{t("rgpd.textes.texte7")}</p>
                </div>
                <div className='Rgpd_texte'>
                    <h2>{t("rgpd.textes.titre8")}</h2>
                    <p>{t("rgpd.textes.texte8")}</p>
                </div>
            </section>
        </main>
    );
};

export default Rgpd;