import React from 'react';
import "./Slide.css"

const Slide = ({linkImage, text}) => {
    return (
        <div className='Slide'>
            <img src={linkImage} className='Slide_img'></img>
            <p>{text}</p>
        </div>
    );
};

export default Slide;