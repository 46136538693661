import React from 'react';
import Slide from '../slide/Slide';
import photo_1 from '../../../assets/dfactoGreen/dfactoGreen_1.jpg'

import prod_1 from '../../../assets/produits/prod16.jpg'
import prod_2 from '../../../assets/produits/prod13.jpg'
import prod_3 from '../../../assets/produits/prod3.png'
import prod_4 from '../../../assets/produits/prod4.png'
import prod_5 from '../../../assets/produits/prod5.png'
import prod_6 from '../../../assets/produits/prod6.png'
import prod_7 from '../../../assets/produits/prod7.png'
import prod_8 from '../../../assets/produits/prod8.png'
import prod_9 from '../../../assets/produits/prod9.png'
import prod_10 from '../../../assets/produits/prod10.png'
import { useTranslation } from 'react-i18next';

const Slider = ({carousel}) => {
    const [t, i18n] = useTranslation("global")

    return (
        <div className='Carousel_slider' ref={carousel}>
            <div className='Carousel_slider' >
                <Slide linkImage={prod_1} text={t("dfactoGreen.body.carrousel.slide_a")} />
                <Slide linkImage={prod_2} text={t("dfactoGreen.body.carrousel.slide_b")} />
                <Slide linkImage={prod_3} text={t("dfactoGreen.body.carrousel.slide_c")} />
                <Slide linkImage={prod_4} text={t("dfactoGreen.body.carrousel.slide_d")} />
                <Slide linkImage={prod_5} text={t("dfactoGreen.body.carrousel.slide_e")} />
                <Slide linkImage={prod_6} text={t("dfactoGreen.body.carrousel.slide_f")} />
                <Slide linkImage={prod_7} text={t("dfactoGreen.body.carrousel.slide_g")} />
                <Slide linkImage={prod_8} text={t("dfactoGreen.body.carrousel.slide_h")} />
                <Slide linkImage={prod_9} text={t("dfactoGreen.body.carrousel.slide_i")} />
                <Slide linkImage={prod_10} text={t("dfactoGreen.body.carrousel.slide_j")} />
            </div>
        </div>
    );
};

export default Slider;