import React, { useEffect } from 'react';
import "./Footer.css"
import logoTriangleLien from "../../assets/dfacto_logo_triangle.png"
import logoFace from "../../assets/logoReseau/face.png"
import logoInsta from "../../assets/logoReseau/insta.png"
import logoLinkdin from "../../assets/logoReseau/linkedin.png"
import { Link } from 'react-router-dom';
import HubspotForm from 'react-hubspot-form'
import ItemMenu from './itemMenu/ItemMenu';
import { useTranslation } from 'react-i18next';


const Footer = () => {

    const [t, i18n] = useTranslation("global")

    return (    
        <footer className='Footer'>
            <div className='Footer_container'>
                <section>
                    <img src={logoTriangleLien} alt='Dfacto logo'></img>
                </section>
                <section className='Footer_container_center'>
                    <ItemMenu link={'./about_us'} linkName={t("footer.nav.link_a")}/>
                    <ItemMenu link={'./our_services'} linkName={t("footer.nav.link_b")}/>
                    <ItemMenu link={'./our_products'} linkName={t("footer.nav.link_c")}/>
                    <ItemMenu link={'./dfacto_green'} linkName={t("footer.nav.link_d")}/>
                    <ItemMenu link={'./references'} linkName={t("footer.nav.link_e")}/>
                    <ItemMenu link={'./catalog'} linkName={t("footer.nav.link_f")}/>
                    <ItemMenu link={'./contact'} linkName={t("footer.nav.link_g")}/>
                </section>
                <section className='Footer_container_center_right'>
                    <ItemMenu link={'./googleMaps'} linkName={t("footer.adresse")} />
                    <ItemMenu link={'./rgpd'} linkName={t("footer.nav.link_h")}/>
                    <a href={"mailto:info@dfacto.be"} linkName={"info@dfacto.be"} className='Link_mail'>info@dfacto.be</a>
                    <div className='Footer_container_reseau'>
                        <a href="https://www.facebook.com/profile.php?id=100093490776166"><img src={logoFace} alt='Logo Facebook' width={24} height={24}></img></a>
                        <a href="https://www.linkedin.com/company/dfacto/about/?viewAsMember=true"><img src={logoLinkdin} alt='Logo LinkedIn' width={24} height={24}></img></a>
                    </div>
                </section>
                <section className='Footer_container_right'>
                    <HubspotForm
                        region={"eu1"}
                        portalId={"139700221"}
                        formId={"512ff043-bbab-466a-a49c-4b4895aad254"}
                        onSubmit={() => console.log('Submit!')}
                        loading={<div>Loading...</div>}
                    />
                </section>
            </div>
        </footer>
    );
};

export default Footer;