import './App.css';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Home from './pages/Home';
import Catalog from './pages/Catalog';
import Contact from './pages/Contact';
import OurProducts from './pages/OurProducts';
import OurServices from './pages/OurServices';
import DfactoGreen from './pages/DfactoGreen';
import References from './pages/References';
import Birefing from './pages/Birefing';
import Products from './pages/products/Products';
import { BrowserRouter, HashRouter, Routes, Route } from 'react-router-dom';
import AboutUs from './pages/AboutUs';
import Rgpd from './pages/rgpd/Rgpd';


function App() {

  
  return (
    <HashRouter >
      <Header />
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/about_us" element={<AboutUs />} />
        <Route path="/catalog" element={<Catalog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/briefing" element={<Birefing />} />
        <Route path="/our_products" element={<OurProducts />} />
        <Route path="/our_services" element={<OurServices />} />
        <Route path="/dfacto_green" element={<DfactoGreen />} />
        <Route path="/references" element={<References />} />
        <Route path="/rgpd" element={<Rgpd />} />
      </Routes>
      <Footer />
      </HashRouter >
    );
}

export default App;
