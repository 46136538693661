import React from 'react';
import './OurProducts.css'
import { Link } from 'react-router-dom';

import art_1 from '../assets/imgProduits/art1.png';
import art_2 from '../assets/imgProduits/art2.png';
import art_3 from '../assets/imgProduits/art4.png';
import art_4 from '../assets/imgProduits/art5.png';
import art_5 from '../assets/imgProduits/art6.png';
import art_6 from '../assets/imgProduits/art7.png';
import art_7 from '../assets/imgProduits/art8.png';
import art_8 from '../assets/imgProduits/art9.png';
import art_9 from '../assets/imgProduits/art10.png';
import art_10 from '../assets/imgProduits/art11.png';
import art_11 from '../assets/imgProduits/art12.png';
import art_12 from '../assets/imgProduits/art13.png';
import art_13 from '../assets/imgProduits/art14.png';
import art_14 from '../assets/imgProduits/art15.png';
import { useTranslation } from 'react-i18next';

const OurProducts = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    const [t, i18n] = useTranslation("global")

    return (
        <main className='OurProducts'>
            <section className='AboutUs_header'>
                <h1 className='animationDown'>{t("products.header.titre")}</h1>
            </section>
            <section className='AboutUs_product '>
                <h1 className='animationLeft'>{t("products.body.titre")}</h1>
                <ul>
                    <li className='animationLeft delay_1'>{t("products.body.liste.texte_a")}</li>
                    <li className='animationLeft delay_2'>{t("products.body.liste.texte_b")}</li>
                    <li className='animationLeft delay_3'>{t("products.body.liste.texte_c")}</li>
                    <li className='animationLeft delay_4'>{t("products.body.liste.texte_d")}</li>
                    <li className='animationLeft delay_5'>{t("products.body.liste.texte_e")}</li>
                    <li className='animationLeft delay_6'>{t("products.body.liste.texte_f")}</li>
                    <li className='animationLeft delay_7'>{t("products.body.liste.texte_g")}</li>
                    <li className='animationLeft delay_8'>{t("products.body.liste.texte_h")}</li>
                    <li className='animationLeft delay_9'>{t("products.body.liste.texte_i")}</li>
                </ul>
            </section>
            <section className='Main_OurProducts'>
                <h1 className='animationLeft delay_9'>{t("products.body.products.titre")}</h1>
                <div className="Main_OurProducts_image">
                    <img src={art_1}></img>
                    <img src={art_2}></img>
                    <img src={art_3}></img>
                    <img src={art_4}></img>
                    <img src={art_5}></img>
                    <img src={art_6}></img>
                    <img src={art_7}></img>
                    <img src={art_8}></img>
                    <img src={art_9}></img>
                    <img src={art_10}></img>
                    <img src={art_11}></img>
                    <img src={art_12}></img>
                    <img src={art_13}></img>
                    <img src={art_14}></img>
                </div>
                <div className="Main_OurProducts_Buttons">
                <Link onClick={scrollToTop} to={'/references'} className="Main_Link">{t("products.body.products.button_a")}</Link>
                <Link onClick={scrollToTop} to={'/catalog'} className="Main_Link">{t("products.body.products.button_b")}</Link>
                </div>
            </section>
        </main>
    );
};

export default OurProducts;