import React from 'react';
import HubspotForm from 'react-hubspot-form'
import './Contact.css'
import CardCarre from '../components/card/carre/CardCarre';

import location from '../assets/icones/map.svg'
import gsm from '../assets/icones/phone.svg'
import mail from '../assets/icones/mail.svg'
import { useTranslation } from 'react-i18next';
const Birefing = () => {
    const [t, i18n] = useTranslation("global")

    return (
        <main className='Contact'>
            <section className='AboutUs_header'>
                <h1 className='animationDown'>{t("contact.header.titreBriefing")}</h1>
            </section>
            <section className='Contact_content'>
                <div className='Contact_content_left'>
                    <CardCarre titre={"info@dfacto.be"} icone={mail} />
                    <CardCarre titre={"+32 (0)2 763 04 80"} icone={gsm} />
                    <CardCarre titre={t("footer.adresse")} icone={location} />
                </div>
                <div className="Contact_content_form">
                    <HubspotForm
                        region={"eu1"}
                        portalId={"139700221"}
                        formId={"8584bc6a-f916-440a-8ad8-1e1f9e388540"}
                        onSubmit={() => console.log('Submit!')}
                        onReady={(form) => console.log('Form ready!')}
                        loading={<div>Loading...</div>}
                    />
                </div>
            </section>
        </main>
    );
};

export default Birefing;