import React from 'react';
import HubspotForm from 'react-hubspot-form'
import './Contact.css'
import CardCarre from '../components/card/carre/CardCarre';

import location from '../assets/icones/map.svg'
import gsm from '../assets/icones/phone.svg'
import mail from '../assets/icones/mail.svg'
import { useTranslation } from 'react-i18next';
const Contact = () => {

    const [t, i18n] = useTranslation("global")

    return (
        <main className='Contact'>
            <section className='AboutUs_header'>
                <h1 className='animationDown'>{t("contact.header.titre")}</h1>
            </section>
            <section className='Contact_content'>
                <div className='Contact_content_left'>
                    <CardCarre titre={"info@dfacto.be"} icone={mail} />
                    <CardCarre titre={"+32 (0)2 763 04 80"} icone={gsm} />
                    <CardCarre titre={t("footer.adresse")} icone={location} />
                </div>
                <div className="Contact_content_form">
                    <h2>{t("contact.body.titre")}</h2>
                    <HubspotForm
                        region={"eu1"}
                        portalId={"139700221"}
                        formId={"44b98f88-97b7-4ec4-a423-6399fdfe7829"}
                        onSubmit={() => console.log('Submit!')}
                        onReady={(form) => console.log('Form ready!')}
                        loading={<div>Loading...</div>}
                    />
                </div>
            </section>
        </main>
    );
};

export default Contact;