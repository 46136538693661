import React, { useState, useRef } from 'react';
import "./Carousel.css"
import flecheSVG from '../../assets/icones/fleche.svg'
import Slider from './slider/Slider';


const Carousel = () => {
    const [index, setIndex] = useState(1)
    const isOverMax = index >= 10
    const isLessMin = index <= 1
    const carousel = useRef(null);

    const onClickPrecedent = () => {
        if (!isLessMin) {
            setIndex(index - 1)
            console.log("p= "+ index)
            carousel.current.style.transform = `translate(${(-580 * (9 + (index-11)))}px, ${0}px)`
        }else {
            setIndex(9)
            carousel.current.style.transform = `translate(${-580 * 9}px, ${0}px)`
        }
    } 

    const onClickNext = () => {
        if (!isOverMax) {
            setIndex(index + 1)
            console.log(index)
            carousel.current.style.transform = `translate(${-580 * index}px, ${0}px)`
        } else {
            setIndex(1)
            carousel.current.style.transform = `translate(${0}%, ${0}px)`
        }
    }

    return (
        <div className='Carousel'>
            <button className='Carousel_button' id='button_left' onClick={onClickPrecedent}>
            <img src={flecheSVG}></img>
            </button>
            <button className='Carousel_button' id='button_right' onClick={onClickNext}>
            <img src={flecheSVG}></img>
            </button>
            <Slider carousel={carousel}/>
        </div>
    );
};

export default Carousel;