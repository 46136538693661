import React from 'react';
import "./Catalog.css"
import Card from '../components/card/Card';

import book from '../assets/icones/book.svg'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Catalog = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    const [t, i18n] = useTranslation("global")

    return (
        <main className='Catalog'>
            <section className='AboutUs_header'>
                <h1 className='animationDown'>{t("catalogs.header.titre")}</h1>
            </section>
            <section className='Catalog_cards'>
                <div className='Catalog_layout'>
                    <h2 className='animationLeft'>{t("catalogs.body.titre")}</h2>
                    <Link onClick={scrollToTop} to={'/briefing'} className="Main_Link animationRight">{t("catalogs.body.button")}</Link>
                </div>
                <p className='animationLeft'>{t("catalogs.body.update")}</p>
                <div className='Catalog_cards_liste'>
                    <a className='animationLeft delay_2' href='https://www.flipsnack.com/9FA75F58B7A/decorex-inspiration-guide-2024-english/full-view.html'><Card icone={book} titre={t("catalogs.body.cards.texte_c")} text={""} /></a>
                    <a className='animationLeft delay_3' href='https://view.publitas.com/md-en/01_24-1-gifts_eng_without-prices_vr/page/1'><Card icone={book} titre={t("catalogs.body.cards.texte_a")} text={""} /></a>
                    <a className='animationLeft delay_4' href='https://www.morethangiftscatalogue.com/france-catalog/fr/eur/'><Card icone={book} titre={t("catalogs.body.cards.texte_d")} text={""} /></a>
                    <a className='animationLeft delay_5' href='https://www.europeancatalog.com/en/?price=ok'><Card icone={book} titre={t("catalogs.body.cards.texte_e")} text={""} /></a>
                    <a className='animationLeft delay_6' href='https://www.textileurope.com/'><Card icone={book} titre={t("catalogs.body.cards.texte_f")} text={""} /></a>
                </div>
            </section>
        </main>
    );
};

export default Catalog;