import React from 'react';
import './Icone.css'
import { Link } from 'react-router-dom';


const Icone = ({linkImage, text, id}) => {
    return (
        <div className='Icone' id={id}>
            <img src={linkImage} alt={"Logo for" + text } width={70} height={70}></img>
            <Link to={"/about_us"}>{text}</Link>
        </div>
    );
};

export default Icone;