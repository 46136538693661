import React from 'react';
import { Link } from 'react-router-dom';
import SelectLangue from '../selectLangue/SelectLangue'
import "./Menu.css"
import { useTranslation } from 'react-i18next';

const Menu = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    const [t, i18n] = useTranslation("global")

    return (
        <nav className='Menu'>
            <ul className='Menu_liste'>
                <li><Link onClick={scrollToTop} to="/about_us" className='Menu_liste_item'>{t("header.link_a")}</Link></li>
                <li><Link onClick={scrollToTop} to="/our_services" className='Menu_liste_item'>{t("header.link_b")}</Link></li>
                <li><Link onClick={scrollToTop} to="/our_products"className='Menu_liste_item'>{t("header.link_c")}</Link></li>
                <li><Link onClick={scrollToTop} to="/dfacto_green"className='Menu_liste_item'>{t("header.link_d")}</Link></li>
                <li><Link onClick={scrollToTop} to="/references"className='Menu_liste_item'>{t("header.link_e")}</Link></li>
                <li><Link onClick={scrollToTop} to="/catalog"className='Menu_liste_item'>{t("header.link_f")}</Link></li>
                <li><Link onClick={scrollToTop} to="/contact"className='Menu_liste_item'>{t("header.link_g")}</Link></li>
                <SelectLangue />
            </ul>
        </nav>
    );
};

export default Menu;