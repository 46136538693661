import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import global_en from "./traductions/en/global.json"
import global_fr from "./traductions/fr/global.json"
import global_nl from "./traductions/nl/global.json"
import global_es from "./traductions/es/global.json"
import global_de from "./traductions/de/global.json"
import i18next from 'i18next'
import { I18nextProvider } from 'react-i18next';

i18next.init({
  interpolation: { espaceValue: false},
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    fr: {
      global: global_fr,
    },
    nl: {
      global: global_nl,
    },
    es: {
      global: global_es,
    },
    de: {
      global: global_de,
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

