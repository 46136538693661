import React, { useEffect } from 'react';
import "./Home.css"
import { Link } from 'react-router-dom';

import iconPuzzle from '../assets/puzzle.png';
import iconQualite from '../assets/qualite.png';
import iconTime from '../assets/time.png';
import iconIdee from '../assets/idee.png';
import iconPrix from '../assets/prix.png';
import Icone from '../components/icone/Icone';
import greenImg from '../assets/greenImg.jpg';

import art_1 from '../assets/imgProduits/art1.png';
import art_2 from '../assets/imgProduits/art2.png';
import art_3 from '../assets/imgProduits/art8.png';
import art_4 from '../assets/imgProduits/art4.png';
import art_5 from '../assets/imgProduits/art5.png';
import art_6 from '../assets/imgProduits/art6.png';
import art_7 from '../assets/imgProduits/art7.png';
import art_8 from '../assets/imgProduits/art9.png';
import art_9 from '../assets/imgProduits/art10.png';
import art_10 from '../assets/imgProduits/art11.png';
import { useTranslation } from 'react-i18next';
import gsap from 'gsap';
import { ScrollToPlugin, ScrollTrigger } from 'gsap/all';


const Home = () => {

    const [t, i18n] = useTranslation("global")

    const slideFromBottom = (elem) => {
        gsap.fromTo(
            elem,
            {
                opacity: 0,
                y: 50,
            },
            {
                opacity: 1,
                y: 0,
                duration:0.4,
                scrollTrigger: {
                    trigger: elem,
                    start: "top bottom",
                    end: "bottom top"
                }
            }
        )
    }

    const slideFromRight = (elem) => {
        gsap.fromTo(
            elem,
            {
                opacity: 0,
                x: -50,
            },
            {
                opacity: 1,
                x: 0,
                duration:0.4,
                scrollTrigger: {
                    trigger: elem,
                    start: "top bottom",
                    end: "bottom top"
                }
            }
        )
    }

    useEffect(() => {
        slideFromRight("#carte1")
    }, [])

    useEffect(() => {
        slideFromRight("#carte2")
    }, [])

    useEffect(() => {
        slideFromRight("#carte3")
    }, [])

    useEffect(() => {
        slideFromRight("#carte4")
    }, [])

    useEffect(() => {
        slideFromRight("#carte5")
    }, [])

    useEffect(() => {
        slideFromBottom("#section1")
    }, [])

    useEffect(() => {
        slideFromBottom("#section2")
    }, [])

    return (
        <main className='Main'>
            <section className='Main_header'>
                <h1 className='animationDown'>{t("home.header.titre")}</h1>
                <p className='animationDown'>{t("home.header.sousTitre")}</p>
            </section> 
            <section className='Main_information' >
                <article className='OurServices_intro_text' id='section1'>
                    <h2>{t("home.ourServices.titre")}</h2>
                    <p>{t("home.ourServices.paragraphe")}</p>
                    <ul className='OurServices_intro_text_liste'>
                        <li>{t("home.ourServices.liste_a")}</li>
                        <li>{t("home.ourServices.liste_b")}</li>
                    </ul>
                    <div className='OurServices_intro_text'>
                        <p>{t("services.body.texte_b")}</p>
                        <p>{t("services.body.texte_c")}</p>
                        <p>{t("services.body.texte_d")}</p>
                    </div>
                </article>
                <aside className='Main_information_right' >
                    <Icone linkImage={iconIdee} text={t("home.cartes.Innovation")} id="carte1"/>
                    <Icone linkImage={iconPuzzle} text={t("home.cartes.Customized")} id="carte2"/>
                    <Icone linkImage={iconTime} text={t("home.cartes.Punctuality")} id="carte3"/>
                    <Icone linkImage={iconPrix} text={t("home.cartes.Price")} id="carte4" />
                    <Icone linkImage={iconQualite} text={t("home.cartes.Quality")} id="carte5" />
                </aside>
            </section>
            <section className='Main_OurProducts'>
                <h2>{t("home.ourProducts.titre")}</h2>
                <div className="Main_OurProducts_image" id='section1'>
                    <img src={art_1} alt='Presentation mark' width={271} height={219}></img>
                    <img src={art_2} alt='Presentation mark' width={271} height={219}></img>
                    <img src={art_3} alt='Presentation mark' width={271} height={219}></img>
                    <img src={art_4} alt='Presentation mark' width={271} height={219}></img>
                    <img src={art_5} alt='Presentation mark' width={271} height={219}></img>
                    <img src={art_6} alt='Presentation mark' width={271} height={219}></img>
                    <img src={art_7} alt='Presentation mark' width={271} height={219}></img>
                    <img src={art_8} alt='Presentation mark' width={271} height={219}></img>
                    <img src={art_9} alt='Presentation mark' width={271} height={219}></img>
                    <img src={art_10} alt='Presentation mark' width={271} height={219}></img>
                </div>
                <div className="Main_OurProducts_Buttons">
                    <Link to={'/references'} className="Main_Link">{t("home.ourProducts.button")}</Link>
                </div>
            </section>
            <section className='Main_DfactoGreen'>
                <img src={greenImg} className='Main_DfactoGreen_img' alt='Dfacto green'></img>
                <article className='Main_DfactoGreen_text'>
                    <h2>{t("home.dfactoGreen.texte")}</h2>
                    <Link to={'/dfacto_green'} className="Main_Link">{t("home.dfactoGreen.button")}</Link>
                </article>
            </section>
        </main>
    );
};

export default Home;
