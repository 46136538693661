import { useRef, useState } from "react"
import "./BurgerMenu.css"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import SelectLangue from "../selectLangue/SelectLangue"

const BurgerMenu = () => {
    const ref = useRef(null)
    const [active, setActive] = useState(false);
    const isActive = active ? "menu-icon menu-icon_active" : "menu-icon"  
    const isActiveBg = active ? "mobile_menu mobile_menu_active" : "mobile_menu"  
    
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    
    }
    const OnClick = () => {
        setActive(!active)
    }

    const OnClickClose = () => {
        setActive(!active)
        scrollToTop()
    }

    const [t, i18n] = useTranslation("global")

    return (
        <>
            <button className="button_menu" onClick={OnClick}>
                <div className={isActive}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </button>
            <div className={isActiveBg} ref={ref}>
                <nav className="mobile_menu_nav">
                    <ul className="mobile_menu_nav_liste">
                    <li><Link onClick={OnClickClose} to="/about_us" className='Menu_liste_item'>{t("header.link_a")}</Link></li>
                    <li><Link onClick={OnClickClose} to="/our_services" className='Menu_liste_item'>{t("header.link_b")}</Link></li>
                    <li><Link onClick={OnClickClose} to="/our_products"className='Menu_liste_item'>{t("header.link_c")}</Link></li>
                    <li><Link onClick={OnClickClose} to="/dfacto_green"className='Menu_liste_item'>{t("header.link_d")}</Link></li>
                    <li><Link onClick={OnClickClose} to="/references"className='Menu_liste_item'>{t("header.link_e")}</Link></li>
                    <li><Link onClick={OnClickClose} to="/catalog"className='Menu_liste_item'>{t("header.link_f")}</Link></li>
                    <li><Link onClick={OnClickClose} to="/contact"className='Menu_liste_item'>{t("header.link_g")}</Link></li>
                    <SelectLangue />
                    </ul>
                </nav>
            </div>
        </>

    )
}

export default BurgerMenu