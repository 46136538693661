import React from 'react';
import './OurServices.css'
import dfactoTriangle from '../assets/services-dfacto.png'
import Card from '../components/card/Card';

import iconPuzzle from '../assets/puzzle.png';
import iconQualite from '../assets/qualite.png';
import iconTime from '../assets/time.png';
import iconIdee from '../assets/idee.png';
import iconPrix from '../assets/prix.png';
import { useTranslation } from 'react-i18next';

const OurServices = () => {
    const [t, i18n] = useTranslation("global")

    return (
        <main className='OurServices'>
            <section className='AboutUs_header'>
                <h1 className='animationDown'>{t("services.header.titre")}</h1>
            </section>
            <section className='OurServices_intro'>
                <div className='OurServices_intro_text'>
                    <h1 className='animationLeft' >{t("services.body.titre")}</h1>
                    <p className='animationLeft delay_1'>{t("services.body.texte")}</p>
                    <ul className='OurServices_intro_text_liste'>
                        <li className='animationLeft delay_2'>{t("services.body.liste.texte_a")}</li>
                        <li className='animationLeft delay_3'>{t("services.body.liste.texte_b")}</li>
                    </ul>
                    <div className='OurServices_intro_text'>
                        <p className='animationLeft delay_4'>{t("services.body.texte_b")}</p>
                        <p className='animationLeft delay_4'>{t("services.body.texte_c")}</p>
                        <p className='animationLeft delay_4'>{t("services.body.texte_d")}</p>
                    </div>
                </div>
                <img className='animationRight delay_4' src={dfactoTriangle}></img>
            </section>
            <section className='OurServices_cards'>
                <h1>{t("services.body.cards.titre")}</h1>
                <div className='OurServices_cards_liste'>
                    <div className='AboutUs_cards'>
                        <Card icone={iconIdee} titre={t("services.body.cards.card_a.titre")} text={t("services.body.cards.card_a.texte")} />
                        <Card icone={iconPuzzle} titre={t("services.body.cards.card_b.titre")} text={t("services.body.cards.card_b.texte")} />
                    </div>
                    <div className='AboutUs_cards'>
                        <Card icone={iconTime} titre={t("services.body.cards.card_c.titre")} text={t("services.body.cards.card_c.texte")} />
                        <Card icone={iconPrix} titre={t("services.body.cards.card_d.titre")} text={t("services.body.cards.card_d.texte")} />
                    </div>
                    <div className='AboutUs_cards'>
                        <Card icone={iconQualite} titre={t("services.body.cards.card_e.titre")} text={t("services.body.cards.card_e.texte")} />
                    </div>
                </div>
            </section>
        </main>
    );
};

export default OurServices;

/* Dfacto juste des idées */