import React from 'react';
import "./References.css"

import eastpak from '../assets/logoMarque/eastpak.png'
import galbani from '../assets/logoMarque/galbani.png'
import gini from '../assets/logoMarque/gini.png'
import godiva from '../assets/logoMarque/godiva-1.png'
import grandcru from '../assets/logoMarque/grandcru.png'
import iglo from '../assets/logoMarque/iglo.png'
import jupiler from '../assets/logoMarque/jupiler.png'
import kidibul from '../assets/logoMarque/kidibul.png'
import kinder from '../assets/logoMarque/kinder.png'
import mcain from '../assets/logoMarque/mcain.png'
import mm from '../assets/logoMarque/mm.png'
import oasis from '../assets/logoMarque/oasis.png'
import pedigree from '../assets/logoMarque/pedigree.png'
import president from '../assets/logoMarque/president.png'
import quick from '../assets/logoMarque/quick.png'
import schweppes from '../assets/logoMarque/schweppes.png'
import selfie from '../assets/logoMarque/selfie.png'
import spa from '../assets/logoMarque/spa.png'
import stella from '../assets/logoMarque/stella.png'
import vittel from '../assets/logoMarque/vittel.png'
import { useTranslation } from 'react-i18next';



const References = () => {
    
    const [t, i18n] = useTranslation("global")
    
    return (
        <main className='References'>
            <section className='AboutUs_header'>
                <h1 className='animationDown'>{t("reference.header.titre")}</h1>
            </section>

            <section className='Main_OurProducts'>
                <h1 className='animationLeft'>{t("reference.body.titre")}</h1>
                <div className="Main_OurProducts_image">
                    <img className='Main_OurProducts_image_img' id='referenceDelay1' src={eastpak} alt='Logo eastpak'></img>
                    <img className='Main_OurProducts_image_img' id='referenceDelay2' src={galbani} alt='Logo galbani'></img>
                    <img className='Main_OurProducts_image_img' id='referenceDelay3' src={gini} alt='Logo gini'></img>
                    <img className='Main_OurProducts_image_img' id='referenceDelay4' src={godiva} alt='Logo godiva'></img>
                    <img className='Main_OurProducts_image_img' id='referenceDelay5' src={grandcru} alt='Logo grandcru'></img>
                    <img className='Main_OurProducts_image_img' id='referenceDelay6' src={iglo} alt='Logo iglo'></img>
                    <img className='Main_OurProducts_image_img' id='referenceDelay7' src={jupiler} alt='Logo jupiler'></img>
                    <img className='Main_OurProducts_image_img' id='referenceDelay8' src={kidibul} alt='Logo kidibul'></img>
                    <img className='Main_OurProducts_image_img' id='referenceDelay9' src={kinder} alt='Logo kinder'></img>
                    <img className='Main_OurProducts_image_img' id='referenceDelay10' src={mcain} alt='Logo mcain'></img>
                    <img className='Main_OurProducts_image_img' id='referenceDelay11' src={mm} alt='Logo mm'></img>
                    <img className='Main_OurProducts_image_img' id='referenceDelay12' src={oasis} alt='Logo oasis'></img>
                    <img className='Main_OurProducts_image_img' id='referenceDelay13' src={pedigree} alt='Logo pedigree'></img>
                    <img className='Main_OurProducts_image_img' id='referenceDelay14' src={president} alt='Logo president'></img>
                    <img className='Main_OurProducts_image_img' id='referenceDelay15' src={quick} alt='Logo quick'></img>
                    <img className='Main_OurProducts_image_img' id='referenceDelay16' src={schweppes} alt='Logo schweppes'></img>
                    <img className='Main_OurProducts_image_img' id='referenceDelay17' src={selfie} alt='Logo selfie'></img>
                    <img className='Main_OurProducts_image_img' id='referenceDelay18' src={spa} alt='Logo spa'></img>
                    <img className='Main_OurProducts_image_img' id='referenceDelay19' src={stella} alt='Logo stella'></img>
                    <img className='Main_OurProducts_image_img' id='referenceDelay20' src={vittel} alt='Logo vittel'></img>
                </div>
                <div className="Main_OurProducts_Buttons">
                </div>
            </section>
        </main>
    );
};

export default References;