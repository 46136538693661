import React from 'react';
import "./SelectLangue.css"
import { useTranslation } from 'react-i18next';
import flagEN from '../../../assets/flags/en.png'
import flagFR from '../../../assets/flags/fr.png'
import flagNL from '../../../assets/flags/nl.png'
import flagES from '../../../assets/flags/es.png'
import flagDE from '../../../assets/flags/de.png'
import { useState } from 'react';

const SelectLangue = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    const [t, i18n] = useTranslation("global")
    const [isOpen, setIsOpen] = useState(false)
    const classNameOpen = isOpen ? "SelectLangue_open" : "SelectLangue"

    const onClickChangeLanguage = (lang) => {
        setIsOpen(!isOpen)
        i18n.changeLanguage(lang)
    }

    const onClickOpen = () => {
        setIsOpen(!isOpen)
    }

    const generateButton = () => {
        switch (t("langue")) {
            case "en":
                return (<div onClick={() => onClickOpen()} className='SelectLangue_button SelectLangue_button_menu'>
                    <img src={flagEN} alt='Flag EN'></img>
                    <p>EN</p>
                </div>)
            case "nl":
                return (<div onClick={() => onClickOpen()} className='SelectLangue_button SelectLangue_button_menu'>
                    <img src={flagNL} alt='Flag NL'></img>
                    <p>NL</p>
                </div>)
            case "fr":
                return (<div onClick={() => onClickOpen()} className='SelectLangue_button SelectLangue_button_menu'>
                    <img src={flagFR} alt='Flag FR'></img>
                    <p>FR</p>
                </div>)
            case "es":
                return (<div onClick={() => onClickOpen()} className='SelectLangue_button SelectLangue_button_menu'>
                    <img src={flagES} alt='Flag ES'></img>
                    <p>ES</p>
                </div>)
            case "de":
                return (<div onClick={() => onClickOpen()} className='SelectLangue_button SelectLangue_button_menu'>
                    <img src={flagDE} alt='Flag DE'></img>
                    <p>DE</p>
                </div>)
        }
    } 
    return (
        <div className={classNameOpen} onClick={() => onClickOpen()}>
            <div className={"SelectLangue_buttons"}>
                <h2 className='SelectLangue_buttons_titre'>{t("header.titreChoixDeLangue")}</h2>
                <div onClick={() => onClickChangeLanguage("en")} className='SelectLangue_button'>
                    <img src={flagEN} alt='Flag EN'></img>
                    <p>EN</p>
                </div>
                <div onClick={() => onClickChangeLanguage("nl")} className='SelectLangue_button'>
                    <img src={flagNL} alt='Flag NL'></img>
                    <p>NL</p>
                </div>
                <div onClick={() => onClickChangeLanguage("fr")} className='SelectLangue_button'>
                    <img src={flagFR} alt='Flag FR'></img>
                    <p>FR</p>
                </div>
                <div onClick={() => onClickChangeLanguage("es")} className='SelectLangue_button'>
                    <img src={flagES} alt='Flag ES'></img>
                    <p>ES</p>
                </div>
                <div onClick={() => onClickChangeLanguage("de")} className='SelectLangue_button'>
                    <img src={flagDE} alt='Flag DE'></img>
                    <p>DE</p>
                </div>
            </div>
            {generateButton()}
        </div>
    );
};

export default SelectLangue;