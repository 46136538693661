import React from 'react';
import './AboutUs.css'
import Card from '../components/card/Card';
import pouce from '../assets/icones/pouce.svg'
import star from '../assets/icones/star.svg'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const AboutUs = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    const [t, i18n] = useTranslation("global")

    return (
        <main className='About_Us'>
            <section className='AboutUs_header'>
                <h1 className='animationDown'>{t("aboutUs.header.titre")}</h1>
            </section>
            <section className='AboutUs_cards'>
                <Card icone={star}
                    titre={t("aboutUs.cards.card_a.titre")}
                    text={t("aboutUs.cards.card_a.texte")}
                    nom={"animationLeft delay"}
                />
                <Card icone={pouce}
                    titre={t("aboutUs.cards.card_b.titre")}
                    text={t("aboutUs.cards.card_b.texte")}
                    nom={"animationRight delay"}
                />
            </section>
            <section className='AboutUs_product animationLeft'>
                <h1>{t("aboutUs.product.titre")}</h1>
                <ul>
                    <li className='animationLeft delay_1' >{t("aboutUs.product.liste.text_a")}</li>
                    <li className='animationLeft delay_2' >{t("aboutUs.product.liste.text_b")}</li>
                    <li className='animationLeft delay_3' >{t("aboutUs.product.liste.text_c")}</li>
                    <li className='animationLeft delay_4' >{t("aboutUs.product.liste.text_d")}</li>
                    <li className='animationLeft delay_5' >{t("aboutUs.product.liste.text_e")}</li>
                    <li className='animationLeft delay_6' >{t("aboutUs.product.liste.text_f")}</li>
                    <li className='animationLeft delay_7' >{t("aboutUs.product.liste.text_g")}</li>
                    <li className='animationLeft delay_8' >{t("aboutUs.product.liste.text_h")}</li>
                    <li className='animationLeft delay_9' >{t("aboutUs.product.liste.text_i")}</li>
                </ul>
                <Link onClick={scrollToTop} className="Button_catalog" to='/catalog'>{t("aboutUs.product.button")}</Link>
            </section>

        </main>
    );
};

export default AboutUs;