import React from 'react';
import { Link } from 'react-router-dom';

const ItemMenu = ({linkName, link}) => {
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <div className='Footer_container_center_link' onClick={scrollToTop}>
            <Link to={link}>{linkName}</Link>
            <span></span>
        </div>
    );
};

export default ItemMenu;