import React from 'react';
import './CardCarre.css'

const CardCarre = ({icone, titre}) => {
    return (
        <div className='CardCarre animationLeft'>
            <div className='CardCarre_header'>
                <img className='CardCarre_header_img' src={icone}></img>
                <h1 className='CardCarre_header_titre'>{titre}</h1>
            </div>
        </div>
    );
};

export default CardCarre;