import React from 'react';
import linkImage from "../../assets/logo_dfacto_simple.png";
import { Link } from 'react-router-dom';
import Menu from './menu/Menu';
import './Header.css'
import BurgerMenu from './burgerMenu/BurgerMenu';

const Header = () => {
    
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <header className='Header'>
            <Link onClick={scrollToTop} to={"./home"}><img className='Header_image' src={linkImage} alt='logo Dfacto'></img></Link>
            <Menu />
            <BurgerMenu />
        </header> 
    );
};

export default Header;