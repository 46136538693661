import React from 'react';
import './Card.css'
const Card = ({text, icone, titre, nom}) => {
    return (
        <div className={"Card" + " " + nom} >
            <div className='Card_header'>
                <img className='Card_header_img' src={icone}></img>
                <h1 className='Card_header_titre'>{titre}</h1>
            </div>
            <p>{text}</p>
        </div>
    );
};

export default Card;