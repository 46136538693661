import React from 'react';
import './DfactoGreen.css'
import Carousel from '../components/carousel/Carousel';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



const DfactoGreen = () => {

    const [t, i18n] = useTranslation("global")

    return (
        <main className='DfactoGreen'>
            <section className='AboutUs_header' id='DfactoGreen_header'>
                <h1 className='animationDown'>{t("dfactoGreen.header.titre")}</h1>
            </section>
            <section className='AboutUs_product'>
                <div>
                    <h2 className='DfactoGreen_product_titre animationLeft'>{t("dfactoGreen.body.titre1")}</h2>
                    <h2 className='DfactoGreen_product_titre animationLeft'>{t("dfactoGreen.body.titre2")}</h2>
                </div>
                <ul>
                    <li className='animationLeft delay_1'>{t("dfactoGreen.body.liste.texte_a")}</li>
                    <li className='animationLeft delay_2'>{t("dfactoGreen.body.liste.texte_b")}</li>
                    <li className='animationLeft delay_3'>{t("dfactoGreen.body.liste.texte_c")}</li>
                    <li className='animationLeft delay_4'>{t("dfactoGreen.body.liste.texte_d")}</li>
                </ul>
            </section>
            <section className='DfactoGreen_carousel animationUp delay_5'>
                <Carousel />
            </section>
            <Link to='/contact' className='Button_catalog'>{t("dfactoGreen.body.button")}</Link>
        </main>
    );
};

export default DfactoGreen;